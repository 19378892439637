import React from "react";
import TacoList from "./TacoList";
import CarouselSlides from "./CarouselSlides";
import HeroBanner from "./Banner";
import ContentMenuComp from "./ContentMenu";
import RichTextComp from "./RichTextComp";
import AccordionList from "./AccordionList";

const ComponentRetriever = (propsData): JSX.Element => {
  const { type, width, content, pathname, pageTitle } = propsData;
  let comp = <></>;
  switch (type) {
    case "carousel":
      comp = <CarouselSlides content={content} width={width} />;
      break;
    case "taco_list":
      comp = <TacoList data={content} width={width} />;
      break;
    case "hero_banner":
      comp = (
        <HeroBanner content={content} width={width} shareTitle={pageTitle} />
      );
      break;
    case "sidebar":
      comp = (
        <ContentMenuComp content={content} width={width} pathname={pathname} />
      );
      break;
    case "rich_text":
      comp = <RichTextComp content={content} width={width} />;
      break;
    case "qa":
      comp = (
        <AccordionList content={content} width={width} pathname={pathname} />
      );
      break;
    default:
      comp = <></>;
  }

  return comp;
};

export default ComponentRetriever;
