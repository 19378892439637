import React from "react";
import { RichtextEditor } from "@cncxt/imodium-component-library";
//import "@cncxt/imodium-component-library/build/richtext.css";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import * as siteContants from "../../utils/constants";
import { verifyCookie } from "../../utils/miscFunctions";
import styles from "./richtext.module.scss";

interface PropTypes {
  content: JSON;
  width: string;
}

const RichTextComp = (data: PropTypes): JSX.Element => {
  const { content, width } = data;
  const breakpoints = useBreakpoint();
  const isCookieAccepted = verifyCookie(siteContants.GDPR_MEDIA_COOKIES);
  return (
    <div style={breakpoints.medium ? { width: width } : {}}>
      <div className={styles["container"]}>
        <RichtextEditor
          mediaCookie={isCookieAccepted}
          jsonRichtextEditor={content}
        />
      </div>
    </div>
  );
};

export default RichTextComp;
