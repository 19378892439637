import React from "react";
import { ContentMenu } from "@cncxt/imodium-component-library";
import styles from "./contentMenu.module.scss";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

interface PropTypes {
  width: string;
  pathname: string;
  content: {
    title: string;
    link: {
      href: string;
      title: string;
      external: boolean;
    };
  }[];
}

const ContentMenuComp = (data: PropTypes): JSX.Element => {
  const { content, width, pathname } = data;
  const breakpoints = useBreakpoint();
  const itemList = content.map(list => {
    return {
      label: list.title,
      link: list.link.href,
      isActive: pathname == list.link.href ? true : false,
    };
  });
  return (
    <div style={breakpoints.medium ? { width: width } : { display: "none" }}>
      <div className={styles["container"]}>
        <ContentMenu listItems={itemList} textColor="primary" />
      </div>
    </div>
  );
};

export default ContentMenuComp;
