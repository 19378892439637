import React from "react";
import { Banner } from "@cncxt/imodium-component-library";
import styles from "./banner.module.scss";
import * as siteContants from "../../utils/constants";
import { verifyCookie } from "../../utils/miscFunctions";

interface BannerProps {
  shareTitle?: string;
  content: {
    image: {
      src: string;
      alt: string;
      title: string;
    };
    content: {
      title: string;
      title_wrapper?: string;
      description: any;
    };
    link?: [];
    width?: string;
  };
  width?: string;
}

const HeroBanner = (data: BannerProps): JSX.Element => {
  const { image, content } = data.content;
  const { shareTitle, width } = data;
  const isCookieAccepted = verifyCookie(siteContants.GDPR_TARGETING_COOKIES);
  let slideAlign = "left";
  let ImageslideAlign = "right";
  let buttonAlign = "rightBottom";
  if (process.env.GATSBY_SITE_DIRECTION == "rtl") {
    slideAlign = "right";
    ImageslideAlign = "left";
    buttonAlign = "leftBottom";
  }
  return (
    <div className={styles["container"]} style={width ? { width: width } : {}}>
      <Banner
        link={image.src}
        alt={image.alt}
        title={content.title}
        desc={content.description}
        size="medium"
        align={slideAlign}
        imgAlign={ImageslideAlign}
        alignVertical="top"
        buttonAlign={isCookieAccepted && buttonAlign}
        shareTitle={shareTitle}
      />
    </div>
  );
};

export default HeroBanner;
