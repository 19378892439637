import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import ComponentRetriever from "../components/ComponentRetriever";
import { randomNumber } from "../utils/miscFunctions";
import { PageProps } from "gatsby";
import Seo from "../components/Seo";
import { Breadcrumb } from "@cncxt/imodium-component-library";

interface pageBuilderInterface extends PageProps {
  pageContext: {
    uuid: string;
    urlPath: string;
    title: string;
    pageType: string;
    components: string;
    footerData: string;
    meta: {
      property_key_name: string;
      property_key_value: string;
      content: string;
    }[];
    canonical: {
      ref: string;
      href: string;
    };
    hreflang: {
      rel: string;
      hreflang: string;
      href: string;
    }[];
    breadcrumbs: {
      title: string;
      link: string;
    }[];
  };
}

const PageBuilder = (data: pageBuilderInterface): JSX.Element => {
  const dataContext = data.pageContext;
  const pathname = data.location.pathname;
  const pageTitle = dataContext.title;
  const footerData = dataContext.footerData;
  const componentData = JSON.parse(dataContext.components);
  const breadcrumbList =
    dataContext.breadcrumbs !== null
      ? dataContext.breadcrumbs.map(path => {
          return { label: path.title, link: path.link };
        })
      : [];
  return (
    <DefaultLayout siteFooterData={footerData}>
      <Seo
        metaArray={dataContext.meta}
        canonicalArray={dataContext.canonical}
        hreflangArray={dataContext.hreflang}
      />
      {dataContext.breadcrumbs !== null && (
        <Breadcrumb listItems={breadcrumbList} variation={"linear"} />
      )}
      {componentData !== null &&
        componentData.map((component, index) => (
          <ComponentRetriever
            type={component.type}
            width={component.width}
            content={component.content}
            pathname={pathname}
            pageTitle={pageTitle}
            key={`home-page-${index + randomNumber}`}
          />
        ))}
    </DefaultLayout>
  );
};

export default PageBuilder;
