import React from "react";
import { Accordion, Anchor } from "@cncxt/imodium-component-library";
import styles from "./accordionList.module.scss";
import { randomNumber } from "../../utils/miscFunctions";

interface AccordionListProps {
  width: string;
  content: {
    qa_question: any;
    qa_answer: any;
  }[];
  pathname: string;
}

interface TextContentProps {
  label: any;
  description: any;
}

interface BackToTopInterface {
  backToTopLabel: string;
  backToTopLink: string;
}

const BackToTop = ({
  backToTopLabel,
  backToTopLink,
}: BackToTopInterface): JSX.Element => {
  return (
    <div className={styles.backToTopWrapper}>
      <Anchor link={backToTopLink}>{backToTopLabel}</Anchor>
    </div>
  );
};

const AccordionList = ({
  content,
  width,
  pathname,
}: AccordionListProps): JSX.Element => {
  return (
    <div className={styles.accordLayout} style={{ width: width }}>
      {content.map((accordion, index) => {
        const accorContent: TextContentProps = {
          label: accordion.qa_question,
          description: accordion.qa_answer,
        };
        return (
          <div
            key={`accordion-${index + randomNumber}`}
            className={styles.accordOuterWrapper}
          >
            <Accordion
              textList={accorContent}
              BackToTop={
                <BackToTop
                  backToTopLabel="Back to top"
                  backToTopLink={pathname + "#"}
                />
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default AccordionList;
