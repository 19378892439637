import React from "react";
import { randomNumber } from "../../utils/miscFunctions";
import { Carousel, Slide } from "@cncxt/imodium-component-library";
import styles from "./carousel.module.scss";
import {
  GATSBY_SHOW_CAROUSEL_PLAY_AND_PLAUSE,
  GATSBY_SITE_DIRECTION,
} from "../../utils/constants";
/*
  Following are the 2 style sheets from the library used for the carousel component
  Need to import in the gatsby application again to apply the styles related to those libraries
  These library will get installed from the @cncxt/imodium-component-library package as dependancy
*/
import "@cncxt/imodium-component-library/build/components/organisms/Carousel/react-multi-carousal/styles.scss";
import "@cncxt/imodium-component-library/build/components/organisms/Carousel/react-circular-progressbar/styles.scss";

interface CarouselSlides {
  content: {
    edit_link: string;
    image: {
      alt: string;
      height: string;
      src: string;
      title: string;
      width: string;
    };
    link: {
      href: string;
      title: string;
    }[];
    slide_layout: string;
    slide_prefix: string;
    slide_speed: string;
    summary: JSON;
    title?: string;
  }[];
  width: string;
}

const CarouselSlides = ({ content, width }: CarouselSlides): JSX.Element => {
  const slideSpeed = parseInt(content[0].slide_speed) * 1000;
  let carouselShowPausePlay = false;
  let slideAlign = "left";
  let ImageslideAlign = "right";
  if (GATSBY_SITE_DIRECTION == "rtl") {
    slideAlign = "right";
    ImageslideAlign = "left";
  }
  if (GATSBY_SHOW_CAROUSEL_PLAY_AND_PLAUSE == "true") {
    carouselShowPausePlay = true;
  }
  return (
    <div dir="ltr" className={styles["container"]} style={{ width: width }}>
      <Carousel
        scrollSlideInfinite={true}
        autoPlay={true}
        autoPlayTime={slideSpeed}
        showDots={true}
        showPausePlay={carouselShowPausePlay}
        direction={GATSBY_SITE_DIRECTION}
      >
        {content.map((slide, index) => (
          <Slide
            dir={process.env.GATSBY_SITE_DIRECTION}
            ImgHeight={428}
            key={`carousel-slide-${index + randomNumber}`}
            link={slide.image.src}
            alt={slide.image.alt}
            title={slide.title}
            desc={slide.summary}
            linkLabel={slide.link[0] ? slide.link[0].title : ""}
            titleTextColor="ternary"
            buttonColor="primary"
            linkUrl={slide.link[0] ? slide.link[0].href : ""}
            size="medium"
            align={slideAlign}
            imgAlign={ImageslideAlign}
          ></Slide>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselSlides;
